import React from 'react';
//import LinkedInIcon from '@material-ui/icons/LinkedIn';
import GitHubIcon from '@material-ui/icons/GitHub';
//import EmailIcon from '@material-ui/icons/Email';
import "../styles/Home.css"
import Link from '@material-ui/core/Link';
function Home() {
  return (
    <div className='home'>
        <div className='about'>
            <h2> Dan D. Alex</h2>
            <div className='prompt'>
                <p>
                Experienced Software Engineer developing solutions for the web, mobile and cloud.
                </p>
               <Link href="https://github.com/danalex7212"  target="_blank" >
          <GitHubIcon/>
        </Link>
            </div>
        </div>
        <div className='skills'>
            <h1>Skills</h1>
            <ol className='list'>
                <li className='item'>
                    <h2> Domains</h2>
                    <span>Web Application Development, Cloud Technologies (AWS ) , Networking ( Socket Programming ), Machine Learning, Data Science/Analytics, Computer Vision , Robotics Algorithms (ROS), Mobile App Development, Unix , AWS CLI</span>
                </li>
                <li className='item'>
                    <h2> Front-End</h2>
                    <span>NextJS, ReactJS, React Native, HTML,CSS,MUI</span>
                </li>
                <li className='item'>
                    <h2> Back-End</h2>
                    <span>Django, Flask, NodeJs, Docker, AWS Lambda, AWS SQS, AWS Api Gateway,
                        AWS S3
                    </span>
                </li>
                <li className='item'>
                    <h2> Database</h2>
                    <span>PostgreSQL, SQLite, AWS DynamoDB, RDS, Firebase</span>
                </li>
                <li className='item'>
                    <h2> Languages</h2>
                    <span>Python,TypeScript, Javascript, C++ , Java</span>
                </li>
            </ol>
        </div>
    </div>
  );
}

export default Home