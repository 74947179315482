import unionArch from "../assets/unionArch.png";
import pmu from "../assets/pmu.png";
import hack from "../assets/hack.png";
import opencv from "../assets/opencv.jpeg";
import fashion from "../assets/fashion.png";
import allign from "../assets/allign.jpeg"
import androidapp from "../assets/android.jpg"
import dscience from "../assets/ds.png"
import ros from "../assets/ros.png"
import slideScanScan from "../assets/slideScanScan.png"
import slideScanSearch from "../assets/slideScanSearch.png"
import slideScanLabel from "../assets/slideScanLabel.png"
import slideScanMobile from "../assets/slideScanMobile.png"
import spotCheckArch from "../assets/spotCheckArch.png"
import spotCheckComplete from "../assets/spotCheckComplete.png"
import spotCheckPhoto from "../assets/spotCheckPhoto.png"
import spotCheckStart from "../assets/spotCheckStart.png"

export const projectList = [
    {
        name: "Slide Scan",
        images: [slideScanScan,slideScanLabel,slideScanMobile,slideScanSearch],
        skills: "TypeScript,NextJs,Amplify,React,Serverless,MUI,DynamoDB",
        description: [
            "Two applications developed for packaging slides and for scanning packages, to track case slide packages sent out of the lab.",
            "Developed custom barcode scanning tray UI for arranging slides , trays and packages hierarchically .",
            "Generated QR code labels for packages are scanned by couriers , pathologists and clients using mobile app.",
            "Has a dashboard for relevant stakeholders to search packages by slide id , destination, date and view their status.",
            "The system allowed tracking cases outside the Lab Information System (LIS), to increase productivity and turnover."
        ],
        link: "https://github.com/danalex7212/"  
    },
    {
        name: "Spot Check",
        images: [spotCheckArch,spotCheckStart,spotCheckPhoto,spotCheckComplete,],
        skills: "React,Serverless,MUI,AWS Lambda, RDS, S3",
        description: [
            "Developed a solution for dermatologists to include photos of the condition, to the biopsies taken from the patient.",
            "Created a simple three step process to scan barcodes and take photos without any patient identifying information.",
            "Backend stores images to be retrieved by API calls by the LIS when biopsy get scanned in the lab."
        ],
        link: "https://github.com/danalex7212/"  
    },
    {
        name: "Union Labs Remote Lab Access System",
        images: [unionArch],
        skills: "Django, AWS Lambda, SQS, RDS, EC2, Boto3, NodeJs, VNC, Docker",
        description: [
            "Built an automated remote lab access system using Virtual Network Computing( VNC).",
            "Completed initial system design for authorising users to access browser based GUIs for servers inside UB network.",
            "Built a JWT authentication API to identify users and interact with the 2FA system with Django REST framework.",
            "Deployed the Django server with NGINX , supervisor and Gunicorn on an Ubuntu EC2 instance .",
            "Designed a PostgreSQL database on AWS RDS for retrieving info about the users and their resources.",
            "Implemented Boto3 (AWS python SDK ) automation of AWS resources to create, terminate EC2s, send messages.",
            "Created a background tasks handling queue using AWS SQS and Lambda functions to decrease response times.",
            "Deployed a NodeJs API on the server to create, terminate processes , generate password and send OTP by email.",
            "Orchestrated docker containers to create isolated GUI enabled desktops for users to conduct independent tests."
        ],
        link: "https://github.com/danalex7212/unionLabs"  
    },
    {
        name: "IoT  Web Application Development",
        images: [pmu],
        skills: "Python, Flask, C++, SQLite, D3, AWS EC2, Docker, NGINX",
        description: [
            "Built a Flask based web application on AWS EC2 instance for remote monitoring and actuation of IoT sensors.",
            "Created a dashboard interface to receive UDP packets (100 Hz/sensor) using python sockets into a SQLite DB.",
            "Generated custom graphs on the front end using D3 JS library for research insights into the data.",
            "Implemented remote actuation of IoT sensors over 4G network using REST API calls from Flask dashboard .",
            "Containerised the entire application and deployed on EC2 using Docker and NGINX.",
        ],
        link: "https://github.com/UB-IoT-Lab/PMU-app"  
    },
    {
        name: "Hardware Automation using a server-less backend",
        images: [hack],
        skills: "AWS Lambda,DynamoDB,API Gateway,S3,React",
        description: [
            "Coordinated a team at UB Hackathon Fall 22, by designing a backend for the team’s client side apps.",
            "For minimal cost and maintenance goals , I designed a backend using AWS server-less services.",
            "Built an API Gateway to handle requests from a React Application served from  S3 and a Raspberry PI client.",
            "The API Gateway forwards request to Lambda functions for processing and storing data.",
            "Designed a NoSQL scheme for storing persistent data from Lambda functions , with minimum reads on DynamoDB.",
        ],
        link: "https://devpost.com/software/flash-your-room?ref_content=my-projects-tab&ref_feature=my_projects",  
    },
    {
        name: "Computer Vision - Panorama Image stitching",
        images: [opencv],
        skills: "Python,OpenCV",
        description: [
            "Extracted SIFT features using OpenCV & matched using KNN .Did RANSAC to estimate homography matrix.",
            "Projective and translation transformation was applied to the respective images to create a panorama.",
        ],
        link: "https://github.com/danalex7212/Projects/blob/master/Panaroma%20Image%20Stitching-Edge%20detection/results/task1_result.jpg",  
    },
    {
        name: "Machine Learning with neural networks",
        images: [fashion],
        skills: "Python, Pandas, Tensorflow-Keras, Numpy",
        description: [
            "Used tensorflow keras to build a neural network with 3 hidden layers to predict individual annual income.",
            "Found optimal hyper-parameters for increasing prediction accuracy using Keras Tuner.",
            "Built a CNN using Keras for classifying clothing items from grayscale images with accuracy greater than 90%.",
        ],
        link: "https://github.com/danalex7212/DataScience-MachineLearning/blob/main/fashion_mnist_above90percentCNN.ipynb",  
    },
    {
        name: "Data Science from scratch",
        images: [dscience],
        skills: "Python, Pandas, Tensorflow-Keras, Numpy",
        description: [
            "Implemented multiple ML algorithms from scratch for classification of handwritten digits in MNIST dataset.",
            "Compared cross validation results between optimal/soft margin classifiers, SVM , Kernel SVM and KNN.",
            "Conducted batch gradient descent and stochastic gradient descent for different classification schemes.",
            "Plotting ROC for logistic regression and comparing results of simple cross and  K-fold validation schemes.",
            "Predicted wine quality from constituent concentrations using OLS equation and ridge regression.",
        ],
        link: "https://github.com/danalex7212/DataScience-MachineLearning",  
    },
    {
        name: "Robotics Algorithms",
        images: [ros],
        skills: "Python, ROS",
        description: [
            "Laser Scan point cloud from a robot was taken to detect walls inside a simulated world in ROS using RANSAC.",
            "The bot was made to follow walls and avoid obstacles while travelling in a straight line from start to finish.",
            "A path from start to finish was planned  using the A-star algorithm with the  given occupancy grid in python.",
            "The simulated robot was programmed to follow the estimated path in ROS.",
        ],
        link: "https://github.com/danalex7212/Projects/tree/master/Wall%20detection%20and%20obstacle%20avoidance%20in%20ROS",
    },
{
        name: "Image Allignment algorithms",
        images: [allign],
        skills: "MATLAB",
        description: [
            "Used Normalized Cross Correlation , SSD , Harris Corner Detection algorithm ( with RANSAC ) for aligning RGB colour filters of images.",
        ],
        link: "https://github.com/danalex7212/Projects/tree/master/Image Allignment-Matlab code",  
    },
    {
        name: "Android App",
        images: [androidapp],
        skills: "Java,Android Studio",
        description: [
            "Build an Android app to display all the sensor data of a typical standard smartphone",
        ],
        link: "https://github.com/danalex7212/Projects/tree/master/Android%20Sensors%20App",  
    },

]