import React from 'react'
import { VerticalTimeline, VerticalTimelineElement, } from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';

import SchoolIcon from "@material-ui/icons/School";
import WorkIcon from "@material-ui/icons/Work";

function Experience() {
  return (
    <div className="experience">
      <VerticalTimeline lineColor="#3e497a">
      <VerticalTimelineElement
          className="vertical-timeline-element--work"
          date="Feb 2023 - Present"
          iconStyle={{ background: "#e9d35b", color: "#fff" }}
          icon={<WorkIcon />}
        >
          <h3 className="vertical-timeline-element-title">
            Software Engineer
          </h3>
          <h4 className="vertical-timeline-element-subtitle">
            A2Z Diagonostics, a2zdx.com
          </h4>
          <ul>
            <li>
              <h4>Slide Scan.</h4>
              <p>TypeScript,NextJs,Amplify,React,Serverless,MUI,DynamoDB</p>
              <ul>
                <li>Two applications developed for packaging slides and for scanning packages, track case slides sent out of the lab.</li>
                <li>Developed custom barcode scanning user interface for arranging slides , trays and packages hierarchically .</li>
                <li>Developed custom barcode scanning user interface for arranging slides , trays and packages hierarchically .</li>
                <li>Has a dashboard for relevant stakeholders to search packages by slide id , destination, date and view their status.</li>
                <li>Has a dashboard for relevant stakeholders to search packages by slide id , destination, date and view their status.</li>
              </ul>
            </li>
            <li>
              <h4>Spot Check</h4>
              <p>React,Serverless,MUI,AWS Lambda, RDS, S3</p>
              <ul>
                <li>Developed a solution for dermatologists to include photos of the condition, to the biopsies taken from the patient.</li>
                <li>Created a simple three step process to scan barcodes and take photos without any patient identifying information.</li>
                <li>Backend stores images to be retrieved by API calls by the LIS when biopsy get scanned in the lab. </li>
              </ul>
            </li>
            <li>
              <h4>Document Verification Automation</h4>
              <p>Python, OpenCV,SQLite</p>
              <ul>
                <li>Writing custom programs to detect barcode and OCR on digital archives to allow destruction of physical archives.</li>
                <li>Lowering on premise physical documentation leads to huge saving in leasing costs from vacated space.</li>
              </ul>
            </li>
          </ul>
        </VerticalTimelineElement>
        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          date="Aug 2022 - Oct 2022"
          iconStyle={{ background: "#e9d35b", color: "#fff" }}
          icon={<WorkIcon />}
        >
          <h3 className="vertical-timeline-element-title">
            Software Developer Intern
          </h3>
          <h4 className="vertical-timeline-element-subtitle">
            FoodFight , getfoodfight.com
          </h4>
          <ul>
            <li>
              <h4>Full Stack Merchant Application</h4>
              <p>Django, Firebase, NodeJs, Clover, PostgreSQL</p>
              <ul>
                <li>Building a web app for merchant interaction with Client side apps and Clover APIs for a sports betting startup.</li>
                <li>Implemented OAuth2 authentication workflow to pull inventory from Clover and  push orders from customers.</li>
                <li>Created a live order view and menu selection interface for the merchant to interact with the Firebase database.</li>
                <li>Designed a PostgreSQL database to cache merchant data and pull client data from remote Firebase database.</li>
                <li>Built a JWT user authentication REST API to facilitate merchant access to inventory and orders.</li>
              </ul>
            </li>
          </ul>
        </VerticalTimelineElement>
        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          date="Feb 2022 - Nov 2022"
          iconStyle={{ background: "#e9d35b", color: "#fff" }}
          icon={<WorkIcon />}>
          <h3 className="vertical-timeline-element-title">
            Research Assistant
          </h3>
          <h4 className="vertical-timeline-element-subtitle">
            University at Buffalo
          </h4>
          <ul>
            <li>
              <h4>Union Labs Remote Lab Access System <a style={{ textDecoration: 'none' }} href='/project/0'>[ Link ]</a></h4>
              <p>Django, AWS Lambda, SQS, RDS, EC2, Boto3, NodeJs, VNC, Docker</p>
        
            </li>
            <li>
              <h4>IoT  Web Application Development <a style={{ textDecoration: 'none' }} href='/project/1'>[ Link ]</a></h4>
              <p>Python, Flask, C++, SQLite, D3, AWS EC2, Docker, NGINX</p>
            </li>
          </ul>
        </VerticalTimelineElement>
        <VerticalTimelineElement
          className="vertical-timeline-element--education"
          date="2021 - 2022"
          iconStyle={{ background: "#3e497a", color: "#fff" }}
          icon={<SchoolIcon />}
        >
          <h3 className="vertical-timeline-element-title">
            University at Buffalo
          </h3>
          <p> MS, Internet of Things (IOT) GPA: 3.93 </p>
          <ul>
            <li>Analysis of Algorithms CSE531</li>
            <li>Computer Vision CSE 573</li>
            <li>Intro to Machine Learning CSE 574</li>
            <li>Robotics Algorithms  CSE 568</li>
            <li>Big Data Analytics  EE559</li>
            <li>Principles of Networking  EE534</li>
          </ul>
        </VerticalTimelineElement>
        
        

        
        
      </VerticalTimeline>
    </div>
  );
}
export default Experience