import React from 'react'
//import LinkedInIcon from '@material-ui/icons/LinkedIn';
import "../styles/footer.css";

function Footer() {
  return (
    <div className='footer'>
        <div className='socialMedia'>
            
        </div>
        
    </div>
  )
}

export default Footer