import React from 'react';
import "../styles/ProjectDisplay.css";
import { useParams } from 'react-router-dom';
import { projectList } from '../helpers/ProjectList';
import GitHubIcon from '@material-ui/icons/GitHub';
import Link from '@material-ui/core/Link';
import ImageCarousel from '../components/ImageCarousel';

function ProjectDisplay() {
    const {id} = useParams();
    const project = projectList[id]
    //const project = projectList.find((obj) => obj.id === id);
    //return <div>{id}</div>
  return (
    <div className = "project">
        <h1>{project.name}</h1>
        {/* <img src={project.image} alt="project_image" /> */}
        <ImageCarousel images={project.images}/>
        <p><b>Skills: {project.skills}</b></p>
        <ul>
          {
            projectList[id].description.map((obj,idx)=>{
              return <li key={idx}>{obj}</li>
            })
          }
        </ul>
        <Link href={project.link}  target="_blank" >
          <GitHubIcon/>
        </Link>
    </div>
  );
}

export default ProjectDisplay