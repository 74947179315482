import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MobileStepper from '@mui/material/MobileStepper';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';


const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

// const images = [
//   {
//     label: 'San Francisco – Oakland Bay Bridge, United States',
//     imgPath:
//       'https://images.unsplash.com/photo-1537944434965-cf4679d1a598?auto=format&fit=crop&w=400&h=250&q=60',
//   },
//   {
//     label: 'Bird',
//     imgPath:
//       'https://images.unsplash.com/photo-1538032746644-0212e812a9e7?auto=format&fit=crop&w=400&h=250&q=60',
//   },
//   {
//     label: 'Bali, Indonesia',
//     imgPath:
//       'https://images.unsplash.com/photo-1537996194471-e657df975ab4?auto=format&fit=crop&w=400&h=250',
//   },
//   {
//     label: 'Goč, Serbia',
//     imgPath:
//       'https://images.unsplash.com/photo-1512341689857-198e7e2f3ca8?auto=format&fit=crop&w=400&h=250&q=60',
//   },
// ];

function SwipeableTextMobileStepper(props) {

    function computeImageDimensions(src, callback) {
        const img = new Image();
        img.onload = function() {
            
          const aspectRatio = this.width / this.height;
          callback({
            width: this.width,
            height: this.height,
            aspectRatio: aspectRatio,
          });
        };
        img.src = src;
      }

const [boxDimensions, setBoxDimensions] = React.useState({width: 700, height: 1000}); // default dimensions

// ...




  const theme = useTheme();
  const [activeStep, setActiveStep] = React.useState(0);
  const maxSteps = props.images.length;

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStepChange = (step) => {
    setActiveStep(step);
  };

  React.useEffect(() => {
    const imageSrc = props.images[activeStep];
    computeImageDimensions(imageSrc, (dims) => {
      // Here, set the maximum width and compute the height based on the aspect ratio
      const maxWidth = 700;
      const computedHeight = maxWidth / dims.aspectRatio;
    //   console.log(dims.width, dims.height);
      setBoxDimensions({ width: dims.width, height:  dims.height });
    });
  }, [activeStep, props.images]);
  return (
    <Box sx={{ maxWidth: 700, flexGrow: 1 }}>
      
      <AutoPlaySwipeableViews
        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
        index={activeStep}
        onChangeIndex={handleStepChange}
        enableMouseEvents={true}
        interval={5000}
        
      >
        {props.images.map((step, index) => (
          <div key={index}>
            {Math.abs(activeStep - index) <= 2 ? (
              <Box
                component="img"
                sx={{
                   
    maxWidth: `${boxDimensions.width}px`,
                  display: 'block',
                  margin: 'auto',
                  
                  overflow: 'hidden',
                  width: '100%',
                }}
                src={step}
                alt={"Project Image"}
              />
            ) : null}
          </div>
        ))}
      </AutoPlaySwipeableViews>
      {props.images.length>1 && <MobileStepper
        steps={maxSteps}
        position="static"
        activeStep={activeStep}
        nextButton={
          <Button
            size="small"
            onClick={handleNext}
            disabled={activeStep === maxSteps - 1}
          >
            Next
            {theme.direction === 'rtl' ? (
              <KeyboardArrowLeft />
            ) : (
              <KeyboardArrowRight />
            )}
          </Button>
        }
        backButton={
          <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
            {theme.direction === 'rtl' ? (
              <KeyboardArrowRight />
            ) : (
              <KeyboardArrowLeft />
            )}
            Back
          </Button>
        }
      /> }
    </Box>
  );
}

export default SwipeableTextMobileStepper;
