import React from 'react';
import ProjectItem from '../components/ProjectItem';

import "../styles/Projects.css";
import { projectList } from '../helpers/ProjectList';

function Projects() {
  return (
    <div className="projects">
      <h1>Projects</h1>
      <div className='projectList'>
        {
          projectList.map((obj,idx)=>{
            return <ProjectItem id={idx} image = {obj.images[0]} name = {obj.name} />
          })
        }
        
      </div>
    </div>
  )
}

export default Projects